import {
    GetFilterAndSorting
} from "../filters/Helpers";
import chikn from "./Chikn";
import farmland from "./Farmland";
import roostr from "./Roostr";
import {
    chiknMetaData
} from '../metadata/Chikn'
import {
    farmlandMetaData
} from '../metadata/Farmland'
import {
    roostrMetaData
} from '../metadata/Roostr'

export const typeInfo = {
    "Chikn": {
        "display": "Chikn",
        "colour": "red",
        "collectionSize": 10000,
        "flavourText": "nice coq!",
        "traits": chiknMetaData,
        "filterSorting": GetFilterAndSorting(chikn)
    },
    "Roostr": {
        "display": "Roostr",
        "colour": "green",
        "collectionSize": 12000,
        "flavourText": "bok bok nc!",
        "traits": roostrMetaData,
        "filterSorting": GetFilterAndSorting(roostr)
    },
    "Farmland": {
        "display": "Farmland",
        "colour": "blue",
        "collectionSize": 5000,
        "flavourText": "Feed me eggs!",
        "traits": farmlandMetaData,
        "filterSorting": GetFilterAndSorting(farmland)
    },
}