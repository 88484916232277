import { Grid, Typography, Container } from "@mui/material"
import { MetaConfig } from "../components/MetaConfig"
import { Section } from "../components/Section"

export const Home = (props) => {
    return (
        <>
            <Grid container spacing={0} sx={{
                bgcolor: 'background.default',
                pt: 4,
                mt: 0,
            }}>
                <MetaConfig
                    pageName={props.title}
                />
                <Container maxWidth="xl">
                    <Grid item xs={12} sx={{ mb: 4 }}>
                        <Typography variant="h4" color="text.main">{props.title}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Grid item xs={12}>
                            <p>testing</p>
                            {props.children}
                        </Grid>
                    </Grid>
                </Container >
                <Section alternate>
                    <p>testing</p>
                    {props.children}
                </Section>
            </Grid >

        </>
    )
}