import { Grid } from "@mui/material"

export const ListingArea = (props) => {
    // const { colSize } = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {props.children}
        </Grid>
    )
}