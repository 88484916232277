import { Helmet } from 'react-helmet'
import { siteConfig } from '../siteConfig'

export const MetaConfig = (props) => {
    const { pageName, description, url, imageUrl, imageWidth, imageHeight } = props

    return (
        <Helmet>
            {/* page */}
            <meta charSet="utf-8" />
            <title>{siteConfig.title}{pageName ? ` - ${pageName}` : ''}</title>
            <meta name='description' content={description || siteConfig.description} />

            {/* meta */}
            <meta name="title" content={siteConfig.title} />
            <meta name="image" content={imageUrl || siteConfig.image} />
            <meta name="url" content={url || siteConfig.url} />

            {/* og */}
            <meta property="og:title" content={siteConfig.title} />
            <meta property="og:description" content={description || siteConfig.description} />
            <meta property="og:image" content={imageUrl || siteConfig.image} />
            <meta property="og:image:width" content={imageWidth || siteConfig.imageWidth} />
            <meta property="og:image:height" content={imageHeight || siteConfig.imageHeight} />
            <meta property="og:url" content={url || siteConfig.url} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={siteConfig.title} />

            {/* twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={siteConfig.title} />
            <meta name="twitter:site" content={siteConfig.twitterHandle} />
            <meta name="twitter:description" content={description || siteConfig.description} />
            <meta name="twitter:image" content={imageUrl || siteConfig.image} />

        </Helmet>
    )
}