import {
    createTheme
} from '@mui/material/styles';

export const theme = createTheme({
    rarities: {
        unique: {
            background: '#d46a6a',
            color: '#fff'
        },
        legendary: {
            background: '#c76bbe',
            color: '#fff'
        },
        elite: {
            background: '#8c6bc7',
            color: '#fff'
        },
        rare: {
            background: '#d4c85e',
            color: '#fff'
        },
        nice: {
            background: '#83c76b',
            color: '#fff'
        },
        common: {
            background: '#4f97c7',
            color: '#fff'
        },
    },
    nftType: {
        chikn: {
            color: '#E65640',
            background: "linear-gradient(15deg, #FF8552 20%, #E65640 45%)"
        },
        roostr: {
            color: '#4f97c7',
            background: "linear-gradient(15deg, #89BCE2 20%, #4f97c7 45%)"
        },
        farmland: {
            color: '#54C750',
            background: "linear-gradient(15deg, #8DE38A 20%, #54C750 45%)"
        },

    },
    palette: {
        primary: {
            main: '#E65640',
        },
        secondary: {
            main: '#E65640',
        },
        alternate: {
            main: '#54C750'
        },
        background: {
            default: '#092638',
            paper: '#031D2E',
            alternate: '#031D2E',
            selected: 'linear-gradient(145deg, #FF8552 0%, #E65640 55%)',
            gradientBlue: 'linear-gradient(145deg, #54C5D6 0%, #1D72B8 55%)'
        },
        text: {
            primary: '#fff',
            secondary: '#13a3d4',
            orange: '#E65640',
            dark: '#3c3c3c'
        },
        divider: '#E65640'
    },
    components: {
        MuiButton: {
            variants: [{
                    props: {
                        variant: "orangeGradient"
                    },
                    style: {
                        background: "linear-gradient(0deg, #FF8552 25%, #E65640 75%)",
                        backgroundSize: "auto 150%",
                        backgroundPositionY: "50%",
                        transition: ".5s",
                        border: 0,
                        borderColor: "transparent",
                        borderRadius: 4,
                        boxShadow: "0 0px 0px 0px rgba(255, 105, 135, .3)",
                        color: "white",
                        height: 48,
                        padding: "0 30px",
                        "&:hover": {
                            backgroundPositionY: "top"
                        }
                    }
                },
                {
                    props: {
                        variant: "blueGradient"
                    },
                    style: {
                        background: "linear-gradient(0deg, #54C5D6 0%, #1D72B8 100%)",
                        backgroundSize: "auto 150%",
                        backgroundPositionY: "50%",
                        transition: ".5s",
                        border: 0,
                        borderColor: "transparent",
                        borderRadius: 4,
                        boxShadow: "0 0px 0px 0px rgba(255, 105, 135, .3)",
                        color: "white",
                        height: 48,
                        padding: "0 30px",
                        "&:hover": {
                            backgroundPositionY: "top"
                        }
                    }
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#031D2E',
                    color: '#fff',
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#fff'
                }
            }
        }
    },
    typography: {
        fontFamily: 'Outfit',
        orangeGradient: {
            background: "linear-gradient(145deg, #FF8552 0%, #E65640 55%)",
            backgroundClip: 'text',
            textFillColor: 'transparent',
            fontFamily: 'Outfit',
            fontWeight: 'bold',
            display: 'block'
        },
        blueGradient: {
            background: "linear-gradient(145deg, #54C5D6 0%, #1D72B8 55%)",
            backgroundClip: 'text',
            textFillColor: 'transparent',
            fontFamily: 'Outfit',
            fontWeight: 'bold',
            display: 'block'
        },
        mainTitle: {
            fontFamily: 'Lobster',
            fontSize: '3.5em',
            textShadow: '0px 0px 8px #061925'
        }
    },
});