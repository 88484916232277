import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import logo from '../Logo.png';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Grid, Link, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { StyledToggleButton, StyledToggleButtonGroup } from './styledComponents/Styles';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            // '&:focus': {
            //     width: '20ch',
            // },
        },
    },
}));



const StyledLink = styled(Link)(({ theme }) => ({
    color: 'inherit',
    fontFamily: theme.typography.fontFamily,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const Flavour = styled(Typography)(({ theme, type }) => ({
    background: theme.nftType[type.toLowerCase()].background,
    backgroundClip: 'text',
    textFillColor: 'transparent',
    textShadow: 'none',
}));

export const NavBar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleChangeSelection = (event, type) => {
        if (type !== null) {
            props.handleNftTypeChange(type)
        }
    }

    return (
        <Box sx={{ flexGrow: 1, zIndex: 99 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleChangeSelection}
                        sx={{ mr: 2, display: { xs: 'flex', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {props.pages.map((page) => (
                            <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                                <StyledLink
                                    component={RouterLink}
                                    underline="hover"
                                    color="inherit"
                                    to={page.path}>
                                    {page.title}
                                </StyledLink>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Box component="div" sx={{ flexGrow: 1 }}>
                        <RouterLink to={"/"} style={{ "textDecoration": "none" }}>
                            <div style={{ "paddingTop": "5px", "paddingBottom": "5px", }}>
                                <Typography nowrap variant="mainTitle" color="text.main">FindMy</Typography>
                                <Flavour type={props.nftType} nowrap variant="mainTitle">Coq</Flavour>
                            </div>
                            {/* <img src={logo} alt="logo" style={{ 'width': '40px' }} /> */}
                        </RouterLink>
                    </Box>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        spacing={3}
                        sx={{ ml: 5, display: { xs: 'none', md: 'flex' } }}>
                        {props.pages.map(page => {
                            return (
                                <Grid item key={page.title}>
                                    <StyledLink
                                        component={RouterLink}
                                        underline="hover"
                                        color="inherit"
                                        to={page.path.toLowerCase()}>
                                        {page.title}
                                    </StyledLink>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <StyledToggleButtonGroup value={props.nftType} size='small' exclusive onChange={(e) => props.onNftChange(e.target.value)}>
                        {Object.values(props.typeInfo).map(type => <StyledToggleButton value={type.display}>{type.display}</StyledToggleButton>)}
                    </StyledToggleButtonGroup>
                    {/* <Search>
                        <SearchIconWrapper>
                            <AccountBalanceWalletOutlinedIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="0x...."
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                </Toolbar>
            </AppBar>
        </Box>
    );
}