import {
    FilterTypes,
    SortDirection
} from "../filters/Helpers";

const filter = [{
        "display": "Head",
        "type": FilterTypes.multiSelect,
        "queryParam": "head",
        "options": [
            "?",
            "daft punk",
            "tungsten cube",
            "golden bone daddy",
            "boxhead",
            "murica",
            "hamlet",
            "inherited royalty",
            "pineapple",
            "icey stare",
            "golden crusader",
            "super saiyan",
            "luckiest red cap",
            "laser eye",
            "goose",
            "cheeky redhead",
            "charcoal brunette",
            "smoking lounge",
            "saint",
            "mysterious fez",
            "all seeing eye",
            "hothead",
            "gentlechikn",
            "mfd",
            "avax safety officer",
            "frothy crown",
            "golden blonde",
            "low vision admiral",
            "crusader",
            "lucky blue cap",
            "construction helmet",
            "red mage",
            "pixel cigarette",
            "gentleman's pipe",
            "hubba bubba",
            "sucker",
            "maverick",
            "top of the morning",
            "nerdlinger",
            "cool dude 69",
            "blue mage",
            "black sweatband",
            "savage beast",
            "moustache",
            "uniquehorn",
            "deal with it",
            "purple sweatband",
            "covid safe",
            "pillager",
            "admiral",
            ""
        ]
    },
    {
        "display": "Neck",
        "type": FilterTypes.multiSelect,
        "queryParam": "neck",
        "options": [
            "?",
            "avax degen",
            "mim og",
            "bitcoin maxi",
            "second head",
            "ethereum gwei guzzler",
            "blue bandana",
            "red bandana",
            "black bandana",
            "mim bling",
            "bitcoin bling",
            "chains for days",
            "dollar chain",
            "ethereum bling",
            "chicken choker",
            "avax bling",
            "wage slave",
            "red bowtie",
            "purple bowtie",
            "hairy old man",
            ""
        ]
    },
    {
        "display": "Torso",
        "type": FilterTypes.multiSelect,
        "queryParam": "torso",
        "options": [
            "?",
            "business boy",
            "karate kid",
            "action shorts",
            "nugget wand",
            "midas muscles",
            "captain chimerica",
            "spell wand",
            "big bag of cash",
            "hentai",
            "popsicle",
            "cutlass",
            "fresh fish",
            "mighty broadsword",
            "human growth hormones",
            "peacemaker",
            "big ol corn cob",
            "summer days",
            "a fresh baguette",
            "old fashioned romance",
            "blue bag",
            "",
        ]
    },
    {
        "display": "Body",
        "type": FilterTypes.multiSelect,
        "queryParam": "body",
        "options": [
            "?",
            "celestial",
            "crystalé",
            "bullion",
            "pixel-chan",
            "gameboy",
            "passion red",
            "frognation green",
            "nightstalker blue",
            "mutant purple",
            "rustic brown",
        ]
    },
    {
        "display": "Feet",
        "type": FilterTypes.multiSelect,
        "queryParam": "feet",
        "options": [
            "?",
            "real feet",
            "red leather boots",
            "swashbucklers",
            "purple leather boots",
            "comfortable uggs",
            "rollerderby",
            "green kicks",
            "red vans",
            "black vans",
            "blue kicks",
            "purple feet",
            "red feet",
            "green feet",
            "blue feet",
            "",
        ]
    },
    {
        "display": "Tail",
        "type": FilterTypes.multiSelect,
        "queryParam": "tail",
        "options": [
            "?",
            "big mama fat pipes",
            "golden plumage",
            "golden egg",
            "gas guzzler",
            "orange plumage",
            "led",
            "red plumage",
            "nyan",
            "very fresh egg",
            "liarliar",
            "",
        ]
    },
    {
        "display": "Background",
        "type": FilterTypes.multiSelect,
        "queryParam": "background",
        "options": [
            "?",
            "galactic",
            "moon",
            "farm",
            "cloud",
            "nugget",
            "miami",
            "vibe",
            "solana",
            "venus",
            "tangerine",
            "periwinkle",
            "turquoisey",
            "plum",
            "royale",
            "cornhusk",
            "vermillion",
        ]
    },
    {
        "display": "Trim",
        "type": FilterTypes.multiSelect,
        "queryParam": "trim",
        "options": [
            "?",
            "cloud trim",
            "space trim",
            "black trim",
            "blue trim",
            "avax trim",
            "",
        ]
    },
    {
        "display": "Traits",
        "type": FilterTypes.multiSelect,
        "queryParam": "_numOfTraits",
        "options": [
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
        ]
    },
    {
        "display": "Rarity",
        "type": FilterTypes.multiSelect,
        "queryParam": "rarity",
        "options": [
            "common",
            "nice",
            "rare",
            "elite",
            "legendary",
            "unique",
        ]
    },
];
const sorting = [{
    "display": "Cheap Coq",
    "direction": SortDirection.asc,
    "queryParam": "salePrice"
}, {
    "display": "Expensive Coq",
    "direction": SortDirection.desc,
    "queryParam": "salePrice"
}, {
    "display": "Smallest Coq",
    "direction": SortDirection.asc,
    "queryParam": "kg"
}, {
    "display": "Fattest Coq",
    "direction": SortDirection.desc,
    "queryParam": "kg"
}, {
    "display": "Worst Coq",
    "direction": SortDirection.desc,
    "queryParam": "rank"
}, {
    "display": "Best Coq",
    "direction": SortDirection.asc,
    "queryParam": "rank"
}, {
    "display": "Recently Sold",
    "direction": SortDirection.desc,
    "queryParam": "lastSoldDate"
}, {
    "display": "Recently Listed", // this has to be FOR SALE so not sure how we can enforce that
    "direction": SortDirection.desc,
    "queryParam": "saleListingDate"
}, {
    "display": "Most Unclaimed Eggs", // this has to also include isRoosted = true and potentially check if lastClaimedEgg != null
    "direction": SortDirection.asc,
    "queryParam": "lastClaimedEgg"
}]

const chikn = {
    "filter": filter,
    "sorting": sorting
}

export default chikn