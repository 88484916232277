import {
    FilterTypes,
    SortDirection
} from "../filters/Helpers";

const filter = [{
        "display": "Head",
        "type": FilterTypes.multiSelect,
        "queryParam": "head",
        "options": [
            "?",
            "Boxhead",
            "Hole in One",
            "Panic Buy",
            "Smol Brain In Jar",
            "Robocoq",
            "Green Party Hat",
            "Bucket",
            "Quarter Pounder",
            "Feed Bag",
            "CRT",
            "Conspiracy Theorist",
            "Golden Templar",
            "Beaker",
            "Quarterback",
            "Plague Doctor",
            "Jell-O",
            "Golden Bone Daddy",
            "Gas Mask",
            "OASIS",
            "Hipster",
            "Batter-Up",
            "Templar",
            "SuperVillain",
            "Daffy",
            "Inherited Royalty",
            "Sombrero",
            "Rosebud",
            "Samurai",
            "Construction Helmet",
            "Grandpa",
            "Chef",
            "Sunnies",
            "Chrome Dome",
            "PermaBull",
            "Gentlechikn",
            "Mullet",
            "Woody",
            "Roodboi Rootie",
            "Coqpit Commander",
            "Snorkel",
            "Sesh Hat",
            "Beret",
            "Framez",
            "Hairy Old Man",
            "Coachella",
            "Fez",
            "Laser Eye",
            "Happy Birthday",
            "Dirty Bird",
            "Beanie",
            "Winterproof",
            "Gentleman's Pipe",
            "3D Specs",
            "Cap",
            "Golden Comb",
            "Celestial Comb",
            "Green Comb",
            "Purple Comb",
            "",
        ]
    },
    {
        "display": "Neck",
        "type": FilterTypes.multiSelect,
        "queryParam": "neck",
        "options": [
            "?",
            "Floatie Boi",
            "Avax Degen",
            "Coq Maxi",
            "Golden Pauldrons",
            "Gwei Guzzler",
            "Bitcoin Maxi",
            "Chick Magnet",
            "Clockchain",
            "Make a Wish",
            "Shoulder Armour",
            "Stop Loss",
            "Bone Necklace",
            "Joseph Drip",
            "Bandolier",
            "Coq Tags",
            "a little rag",
            "Lei",
            "Red Bowtie",
            "Sash",
            "Purple Bowtie",
            "Prized Coq",
            "Stethoscope",
            "",
        ]
    },
    {
        "display": "Torso",
        "type": FilterTypes.multiSelect,
        "queryParam": "torso",
        "options": [
            "?",
            "Snib Snibs",
            "Golden Breastplate",
            "Midas Muscles",
            "Doctor Coq",
            "Dad Shirt",
            "Snip Snips",
            "Grande LeCoq",
            "Kevlar",
            "Tweed",
            "Gameboi",
            "Play Boy",
            "Human Growth Hormones",
            "Black T-Shirt",
            "GoldFish",
            "White T-Shirt",
            "Red T-Shirt",
            "Breastplate",
            "Hendrix",
            "Floppy Disk",
            "Saddle",
            "Boombox",
            "McFly",
            "Banjo",
            "Not Football",
            "Bone",
            "Zippo",
            "Fancy Sword",
            "Rubber chikn",
            "Fresh Fish",
            "Lord Quas",
            "Katana",
            "Omelette",
            "Shield",
            "Love Balloon",
            "Home Run",
            "Hoops",
            "Avax Banger",
            "Gainz",
            "Banger",
            "CD-RW",
            "Sword",
            "Big Stick",
            "Starfish",
            "Big Ol' Corn Cob",
            "Shovel",
            "Spork",
            "Spelunker",
            "9-iron",
            "The Key",
            "A Fresh Baguette",
            "Toot-Toot",
            "",
        ]
    },
    {
        "display": "Body",
        "type": FilterTypes.multiSelect,
        "queryParam": "body",
        "options": [
            "?",
            "Celestial",
            "Bullion",
            "Obsidian",
            "Midnight",
            "Mutant Purple",
            "Frog Green",
            "Passion Red",
            "Nightstalker Blue",
            "Snow White",
        ]
    },
    {
        "display": "Feet",
        "type": FilterTypes.multiSelect,
        "queryParam": "feet",
        "options": [
            "?",
            "Rugged",
            "Golden Greaves",
            "Red Stilettos",
            "Black Stilettos",
            "Greaves",
            "Blue Timbs",
            "Spurs",
            "Ball'n Chain",
            "All Stars",
            "Sneaker Head",
            "Iron Claw",
            "Buckaroo",
            "Croqs",
            "Golden Feet",
            "Celestial Feet",
            "Green Feet",
            "Red Feet",
            "",
        ]
    },
    {
        "display": "Tail",
        "type": FilterTypes.multiSelect,
        "queryParam": "tail",
        "options": [
            "?",
            "Concorde",
            "Scorpion King",
            "Prawn Coqtail",
            "Hydra",
            "WhaleGod",
            "Peacoq",
            "Off Grid",
            "Sword Tail",
            "Big Mama Fat Pipes",
            "Royal Flush",
            "Foliage",
            "Smokestack",
            "Gas Guzzler",
            "chikn Jockey",
            "",
        ]
    },
    {
        "display": "Background",
        "type": FilterTypes.multiSelect,
        "queryParam": "background",
        "options": [
            "?",
            "Field Glitch",
            "Vaporwave Glitch",
            "Farm Glitch",
            "Galactic Glitch",
            "Galactic",
            "Vaporwave",
            "Vulcan",
            "Atlantis",
            "Drummies",
            "Farm",
            "Field",
            "Vibe",
            "Miami",
            "Venus",
            "Solana",
            "Royale",
            "Tangerine",
            "Vermillion",
            "Plum",
            "Turquoisey",
            "Periwinkle",
            "Cornhusk",
        ]
    },
    {
        "display": "Trim",
        "type": FilterTypes.multiSelect,
        "queryParam": "trim",
        "options": [
            "?",
            "Space Trim",
            "Atlantis Trim",
            "Vulcan Trim",
            "Avax Trim",
            "Blue Trim",
            "Black Trim",
            "",
        ]
    },
    {
        "display": "Traits",
        "type": FilterTypes.multiSelect,
        "queryParam": "_numOfTraits",
        "options": [
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
        ]
    },
    {
        "display": "Rarity",
        "type": FilterTypes.multiSelect,
        "queryParam": "rarity",
        "options": [
            "common",
            "nice",
            "rare",
            "elite",
            "legendary",
            "unique",
        ]
    },
];

const sorting = [{
    "display": "Cheap Coq",
    "direction": SortDirection.asc,
    "queryParam": "salePrice"
}, {
    "display": "Expensive Coq",
    "direction": SortDirection.desc,
    "queryParam": "salePrice"
}, {
    "display": "Smallest Coq",
    "direction": SortDirection.asc,
    "queryParam": "kg"
}, {
    "display": "Fattest Coq",
    "direction": SortDirection.desc,
    "queryParam": "kg"
}, {
    "display": "Worst Coq",
    "direction": SortDirection.desc,
    "queryParam": "rank"
}, {
    "display": "Best Coq",
    "direction": SortDirection.asc,
    "queryParam": "rank"
}, {
    "display": "Last Claimed Eggs",
    "direction": SortDirection.asc,
    "queryParam": "rank"
}]

const roostr = {
    "filter": filter,
    "sorting": sorting
}

export default roostr