import { MenuItem } from "@mui/material"
import SortIcon from '@mui/icons-material/Sort';
import { useState, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { StyledSelect, StyledFormControl, MenuStyle } from "../styledComponents/Styles";

export const Sort = (props) => {
    const initialSorting = (sorting) => sorting[0]

    const { sorting, onSortingChange } = props
    const [nftType] = useOutletContext()
    const [sortOption, setSortOption] = useState(initialSorting(sorting))

    useEffect(() => {
        onSortingChange(sortOption)
    }, [sortOption, onSortingChange])

    useEffect(() => {
        console.log('setting initial filters')
        setSortOption(() => initialSorting(sorting))
    }, [nftType.display, sorting])

    const handleSortChange = (e) => {
        console.log(e.target.value)
        setSortOption(e.target.value)
    }

    return (
        <StyledFormControl sx={{ flexDirection: 'row-reverse' }}>
            <StyledSelect
                disableUnderline
                MenuProps={{
                    PaperProps: {
                        sx: MenuStyle,
                        elevation: 8
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    getContentAnchorEl: null
                }}
                IconComponent={() => <SortIcon sx={{ color: (theme) => theme.palette.primary.main }} />}
                value={sortOption}
                onChange={handleSortChange}
            >
                {sorting.map(s => {
                    return (
                        <MenuItem key={s.queryParam + s.direction} value={s}>
                            {/* <ListItemIcon> */}
                            {/* <SortIcon /> */}
                            {/* {s.direction === SortDirection.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                            {/* </ListItemIcon> */}
                            {s.display}
                        </MenuItem>
                    )
                })}
            </StyledSelect>
        </StyledFormControl>
    )
}