import './App.css';
import { Grid } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theming/theme';
import { NavBar } from './components/NavBar';
import { useState } from 'react';
import { typeInfo } from './TypeInfo/TypeInfo';
import './styles/style.css'

const pages = (nftType) => [
  {
    path: `/snipe/${nftType}`,
    title: `Snipe`
  },
  {
    path: `/collection/${nftType}`,
    title: `Collections`
  },
  // {
  //   path: `/floor/${nftType}`,
  //   title: `Floor`
  // },
  // {
  //   path: `/transactions/${nftType}`,
  //   title: `Transactions`
  // },
  {
    path: `/traits/${nftType}`,
    title: `Traits`
  },
]



const App = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const [nftType, setNftType] = useState(typeInfo.Chikn)

  const handleNftTypeChange = (newType) => {
    setNftType(typeInfo[newType])
    const basePath = location.pathname.split("/")[1]
    const newPath = `/${basePath}/${newType.toLowerCase()}`
    if (newPath !== location.pathname) navigate(newPath)
  }


  return (
    <ThemeProvider theme={theme} >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12} >
          <NavBar pages={pages(nftType.display)} nftType={nftType.display} typeInfo={typeInfo} onNftChange={handleNftTypeChange} />
        </Grid>
        <Grid item xs={12} >
          <Outlet context={[nftType]} />
        </Grid>
      </Grid>
    </ThemeProvider>

  );
}

export default App;