import styled from "@emotion/styled"
import { Autocomplete, FormControl, Select, ToggleButton, ToggleButtonGroup } from "@mui/material"

// ---------------- FILTER DROPDOWNS
export const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputLabel-root': {
        color: 'white'
    },
    '& .MuiInputLabel-shrink': {
        color: theme.palette.primary.main
    },
    '& .MuiChip-deleteIcon:hover': {
        color: 'white'
    },
    '& .MuiAutocomplete-clearIndicator, .MuiAutocomplete-popupIndicator, .MuiOutlinedInput-notchedOutline': {
        color: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiChip-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        '& .MuiChip-deleteIcon': {
            color: theme.palette.primary.main

        },
        '& .MuiChip-deleteIcon:hover': {
            color: 'white'
        },
    },
    '& .MuiInputBase-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.35)'
    }
}))
// ---------------- FILTER DROPDOWNS END


// ---------------- TOGGLE BUTTONS
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        color: "white",

    }
}))

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.Mui-selected': {
        background: theme.palette.background.selected,
        color: 'black'
    }
}))
// ---------------- TOGGLE BUTTONS END


// ---------------- SELECT FOR SORT
export const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input': {
        color: theme.palette.primary.main,
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'uppercase',
        fontFamily: theme.typography.fontFamily,
        padding: 7,
        borderRadius: '4px',
        display: 'flex',
        '& .MuiListItemIcon-root, .icon': {
            color: theme.palette.primary.main,
            pointerEvents: 'none'
        },
        '& ul, li': {
            background: 'white'
        }
    },
}))
// ---------------- SELECT FOR SORT END


// ---------------- FORM CONTROL FOR SORT
export const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        flexDirection: 'row-reverse',
        padding: 1,
        paddingLeft: 3,
        'fieldset.MuiOutlinedInput-notchedOutline': {
            outline: 0,
            borderWidth: 0,
        },
        '&.Mui-focused': {
            background: 'rgba(230, 86, 64, 0.04)'
        }
    },
    '& .MuiOutlinedInput-root:hover': {
        background: 'rgba(230, 86, 64, 0.04)'
    },
}))
// ---------------- FORM CONTROL FOR SORT END


// ---------------- MENU STYLE FOR SORT
export const MenuStyle = (theme) => ({
    '& ul': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& li:hover, li.Mui-selected': {
        background: theme.palette.background.selected
    },
})
// ---------------- MENU STYLE FOR SORT END
