import { Grid, Container } from "@mui/material"



export const Section = (props) => {
    return (
        <Grid container spacing={0} sx={{
            bgcolor: props.alternate ? "background.alternate" : 'background.default',
            borderRadius: props.radius ? '5px' : '0px',
            ...props.sx
        }}>
            {
                props.noPadding ?
                    <Container maxWidth="xl" style={{ padding: 0 }}>
                        {props.children}
                    </Container>
                    :
                    <Container maxWidth="xl" sx={{ p: 2 }}>
                        {props.children}
                    </Container>
            }

        </Grid >
    )
}