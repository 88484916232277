import { FilterList } from "@mui/icons-material"
import { Button, Grid, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { Filter } from "../../components/Filter/Filter"
import { Sort } from "../../components/Filter/Sort";
import { ListingArea } from "../../components/ListingArea"

const t = [1, 2, 3, 4, 5]


export const Collection = (props) => {
    const [nftType] = useOutletContext();

    const [filterOpen, setFilterOpen] = useState(false)
    const [filterParams, setFilterParams] = useState(null)
    const [sortOption, setSortOption] = useState(null)

    useEffect(() => {
        console.log('queryParams', filterParams)
        console.log('sortOption', sortOption)
    }, [filterParams, sortOption])


    const handleFilterChange = (params) => {
        setFilterParams(params)
    }

    const handleSortChange = (option) => {
        setSortOption(option)
    }

    const onFilterOpen = () => setFilterOpen(() => !filterOpen)

    const filter = nftType.filterSorting?.filter
    const sorting = nftType.filterSorting?.sorting

    return (
        <>
            <Box>{JSON.stringify(filterParams)}</Box>
            <Box>{JSON.stringify(sortOption)}</Box>
            <Box id="collection-layout" sx={{}} >
                <Box id="filter-button-section">
                    <Button onClick={() => onFilterOpen()}><FilterList sx={{ mr: 1 }} />Filter</Button>
                </Box>
                <Box id="sort-options-section" sx={{ ml: 'auto' }}>
                    {/* <Button onClick={() => console.log('nice')}><SortIcon sx={{ mr: 1 }} />Sort</Button><Select></Select> */}
                    <Sort sorting={sorting} onSortingChange={handleSortChange} />
                </Box>
                <Box id="filter-items-section">
                    <Grid container direction="row" sx={12} spacing={2} >
                        <Grid className={!filterOpen && 'hidden'} item xs={12} md={3} sx={{ paddingTop: 0 }} >
                            {filter && <Filter filter={filter} onFilterChange={handleFilterChange} />}
                        </Grid>
                        <Grid item xs={12} md={filterOpen ? 9 : 12}>
                            <ListingArea>
                                {t.map(v =>
                                    <Grid key={t} sx={{ display: 'flex' }} xs={12} md={filterOpen ? 4 : 3} item>
                                        <Box sx={{ background: 'rgba(0,0,0,0.5)', height: 'auto', width: '100%' }}>
                                            <img src="https://chikn-farm.sfo3.cdn.digitaloceanspaces.com/chikn/thumb/639766e57918ae4006d4baa0927c2132fd4e18f8.jpeg" />
                                            <Typography>{v}</Typography>
                                        </Box>

                                    </Grid>)}
                            </ListingArea>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}