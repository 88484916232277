import { Grid, Typography, Container } from "@mui/material"
import { MetaConfig } from "./MetaConfig"

export const Layout = (props) => {
    return (
        <>
            <Grid container spacing={2} sx={{
                bgcolor: 'background.default',
                pt: 4,
                mt: 0,
                height: '100%'
            }}>
                <MetaConfig
                    pageName={props.title}
                />
                <Container maxWidth="xl" sx={{ minHeight: 'calc(100vh - 112px)' }}>
                    <Grid item xs={12} sx={{ mb: 4 }}>
                        <Typography sx={{ textTransform: "capitalize" }} variant="h4" color="text.main">{props.title}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Grid item xs={12}>
                            {props.children}
                        </Grid>
                    </Grid>
                </Container >
            </Grid >
        </>
    )
}