import { Grid } from "@mui/material"
import { useState, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { FilterTypes } from "../../filters/Helpers"
import { MultiSelect } from "./MultiSelect"
import { NonNullableButtonGroup } from "./NonNullableButtonGroup"
import cloneDeep from 'lodash.clonedeep'

const initFilter = (filter) => {
    var init = {}
    filter.forEach(f => {
        if (f.type === FilterTypes.buttonGroup && f.defaultIndex !== "undefined") {
            init[f.queryParam] = f.options[f.defaultIndex].value
        }
    })
    return init
}

export const Filter = (props) => {
    const { filter, onFilterChange } = props
    const [nftType] = useOutletContext()
    const [filters, setFilters] = useState(initFilter(filter))

    useEffect(() => {
        onFilterChange(filters)
    }, [filters, onFilterChange])

    useEffect(() => {
        setFilters(() => initFilter(filter))
    }, [nftType.display, filter])

    const updateFilters = (filterParam, value) => {
        var _value = typeof value === "object" ? value.map(v => v.value) : value
        setFilters((prevFilter) => {
            var newFilter = cloneDeep(prevFilter)
            newFilter[filterParam] = _value
            return newFilter
        })
    }

    return (
        <Grid container rowSpacing={1.5} xs={12}>
            {filter.map(f => {
                switch (f.type) {
                    case FilterTypes.buttonGroup:
                        return <NonNullableButtonGroup key={f.display} updateFilters={updateFilters} display={f.display} options={f.options} queryParam={f.queryParam} nftType={nftType} />
                    case FilterTypes.multiSelect:
                        return <MultiSelect key={f.display} updateFilters={updateFilters} display={f.display} options={f.options} queryParam={f.queryParam} nftType={nftType} />
                    default:
                        return null
                }
            })}
        </Grid>
    )
}