import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import '@fontsource/outfit/300.css';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/700.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Home } from './routes/Home';
import { Wallet } from './routes/Wallet';
import { WalletSearch } from './routes/WalletSearch';
import { CollectionLayout } from './routes/Collection/CollectionLayout';
import { TraitOverview } from './routes/TraitOverview';
import { Error } from './routes/Error';

ReactDOM.render(
  <BrowserRouter >
    <Routes>
      <Route path="/" element={<App />} >
        {/* index */}
        <Route index element={<Home title={"Home"} />} />
        {/* wallet */}
        <Route path="wallet" element={<WalletSearch title={"Wallet"} />}>
          <Route path=":walletId" element={<Wallet title={"Wallet"} />} />
        </Route>
        {/* search collection */}
        <Route path="collection/:nftType" element={<CollectionLayout title={"collection"} />}>
          <Route index element={<p>NFT TYPE SELECTOR HERE</p>} />
        </Route>
        {/* trait overview */}
        <Route path="traits" element={<TraitOverview title={"Traits"} />} />
        <Route path="*" element={<Error title={"Shhh, the secret pages go here.."} />} />
      </Route>
    </Routes >
  </BrowserRouter >,
  document.getElementById('root')
);
