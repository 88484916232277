import { Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { Section } from "../components/Section"


export const WalletSearch = (props) => {
    return (
        <>
            <Layout title={props.title}>
                <Typography>{props.title}</Typography>
                <Outlet />
            </Layout>
        </>
    )
}