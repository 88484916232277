import { Layout } from '../components/Layout';

export const Error = (props) => {
    return (
        <Layout title={props.title} >
            <p>Todos</p>
            <p>Filter panel & how to make it dynamic per nft type</p>
            <p>Listing page & how to make it dynamic per nft type</p>
            <p></p>
        </Layout>
    )
}