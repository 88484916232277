import {
    FilterTypes,
    SortDirection
} from "../filters/Helpers";

const filter = [{
        "display": "Tiles",
        "type": FilterTypes.multiSelect,
        "queryParam": "tiles.tile",
        "options": [
            "Mountain_Crystal",
            "SmallFarm_Empty",
            "Roqs",
            "Old_Windmill",
            "Grass_Trees",
            "SmallFarm_Cabbage",
            "Meadow",
            "Hills",
            "Mountain",
            "Ruin",
            "Orchard",
            "Grass_Full",
            "Wheat",
            "Woods",
            "Grass_Mushrooms",
            "BigHole",
            "Grass_Thin",
            "BoneDaddy",
            "Meteor",
            "pyramid_Avax",
            "SmallFarm_Corn",
            "SmallFarm_Wheat_Fence",
            "CoqRoqs",
            "SmallFarm_Wheat",
            "Tungsten",
            "Pond_Circle",
            "Pond_Corner",
            "ChiknMountain",
            "Golf",
            "Red_Windmill",
            "SmallFarm_Corn_Fence",
            "Corn",
            "SmallFarm_Cabbage_Fence",
            "Excalibur",
            "Water",
            "Graveyard",
            "HelicopterPad",
            "pyramid_Annuit",
            "Goldmine"
        ],
    },
    {
        "display": "Bigness",
        "type": FilterTypes.multiSelect,
        "queryParam": "bigness",
        "options": [
            "humble",
            "big",
            "vast",
            "massive",
            "infinite",
        ]
    },
    {
        "display": "Rarity",
        "type": FilterTypes.multiSelect,
        "queryParam": "rarity",
        "options": [
            "wholesome",
            "lush",
            "bountiful",
            "supurb",
            "marvelous",
        ]
    }
]
const sorting = [{
    "display": "Cheapest",
    "direction": SortDirection.asc,
    "queryParam": "salePrice"
}, {
    "display": "Expensive",
    "direction": SortDirection.desc,
    "queryParam": "salePrice"
}, {
    "display": "Smallest",
    "direction": SortDirection.asc,
    "queryParam": "sizeRank"
}, {
    "display": "Biggest",
    "direction": SortDirection.desc,
    "queryParam": "sizeRank"
}, {
    "display": "Worst Score",
    "direction": SortDirection.asc,
    "queryParam": "score"
}, {
    "display": "Best Score",
    "direction": SortDirection.desc,
    "queryParam": "score"
}, {
    "display": "Worst avg/tile",
    "direction": SortDirection.asc,
    "queryParam": "averagePerTile"
}, {
    "display": "Best avg/tile",
    "direction": SortDirection.desc,
    "queryParam": "averagePerTile"
}, {
    "display": "Lowest Multiplier",
    "direction": SortDirection.asc,
    "queryParam": "multiplier"
}, {
    "display": "Highest Multiplier",
    "direction": SortDirection.desc,
    "queryParam": "multiplier"
}, {
    "display": "Recently Sold",
    "direction": SortDirection.desc,
    "queryParam": "lastSoldDate"
}, {
    "display": "Recently Listed", // this has to be FOR SALE so not sure how we can enforce that
    "direction": SortDirection.desc,
    "queryParam": "saleListingDate"
}]

const farmland = {
    "filter": filter,
    "sorting": sorting
}

export default farmland