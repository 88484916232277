export const FilterTypes = {
    multiSelect: "multi-select",
    select: "select",
    buttonGroup: "button-group",
    slider: "slider",
    checkbox: "checkbox",
}

export const SortDirection = {
    asc: "sortAsc",
    desc: "sortDesc",
}

export const GetFilterAndSorting = ({ filter, sorting }) => {
    return {
        filter: SetupFilter(filter),
        sorting: sorting
    }
}


const NoneCatch = (v) => v === "" ? "None" : v;

const SetupFilter = (filter) => {
    var filters = [{
        "display": "Market Settings",
        "type": FilterTypes.buttonGroup,
        "queryParam": "forSale",
        "defaultIndex": 0,
        "options": [{
            "display": "For Sale",
            "value": true
        }, {
            "display": "Show All",
            "value": false
        }]
    }];
    filter.forEach(f => {
        filters.push({
            "display": f.display,
            "type": f.type,
            "queryParam": f.queryParam,
            "options": f.options.map(o => {
                return {
                    display: NoneCatch(o),
                    value: o
                }
            })
        })
    })
    //-------------------also include sniper mode ;)
    // Object.keys(metaData).forEach(k => filters.push(CreateMultiSelect(metaData, k)))
    return filters
}