import { Outlet, useOutletContext } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { Section } from '../../components/Section';
import { Collection } from './Collection';



export const CollectionLayout = (props) => {
    const [nftType] = useOutletContext();

    return (
        <Layout title={`${nftType.display || ""} ${props.title}`}>
            <Section alternate noPadding sx={{ mb: 4 }}>
                <p>Area here for floor prices per rarity</p>
            </Section>
            <Section noPadding>
                <Collection nftType={nftType} />
            </Section>
            <Outlet />
        </Layout >
    )
}