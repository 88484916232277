import { Typography } from '@mui/material';
import { Layout } from '../components/Layout';
import { Section } from "../components/Section"


export const Wallet = (props) => {
    return (
        <>
            <Section  >
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
                <Typography>test</Typography>
            </Section>
        </>
    )
}