import { Grid } from "@mui/material"
import { useState, useEffect } from "react"
import { StyledToggleButtonGroup, StyledToggleButton } from "../styledComponents/Styles"

export const NonNullableButtonGroup = (props) => {
    const { options, updateFilters, queryParam, nftType } = props
    const defaultValue = options[0].value
    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
        setValue([defaultValue])
    }, [nftType, defaultValue])

    const handleChange = (e, v) => {
        if (v !== null) {
            updateFilters(queryParam, v)
            setValue(() => v)
        }
    }

    return (
        <Grid item xs={12} sx={{ mb: 1 }}>
            <StyledToggleButtonGroup
                value={value}
                exclusive
                size='small'
                onChange={handleChange}
            >
                {props.options.map(option => {
                    return (
                        <StyledToggleButton key={option.display} value={option.value}>{option.display}</StyledToggleButton>
                    )
                })}
            </StyledToggleButtonGroup>
        </Grid>
    )
}