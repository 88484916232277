import { Grid, TextField } from "@mui/material"
import { useState, useEffect } from "react"
import { StyledAutoComplete, MenuStyle } from "../styledComponents/Styles"

export const MultiSelect = (props) => {
    const { options, updateFilters, queryParam, nftType } = props

    const [selections, setSelections] = useState([])

    useEffect(() => {
        setSelections([])
    }, [nftType])

    const handleChange = (value) => {
        var _selections = [...value]
        updateFilters(queryParam, _selections)
        setSelections(() => _selections)
    }

    return (
        <Grid item xs={12}>
            <StyledAutoComplete
                multiple
                value={selections}
                onChange={(event, value) => handleChange(value)}
                disableCloseOnSelect
                isOptionEqualToValue={(o, v) => { return o.value === v.value }}
                getOptionLabel={(option) => option.display}
                id={`tags-standard-${props.display}`}
                options={options}
                ListboxProps={{
                    sx: MenuStyle
                }}
                autoHighlight
                autoComplete
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params}
                        label={props.display}
                        placeholder="Filter.." />
                )}
            />
        </Grid>
    )
}